import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { get } from 'lodash-es';
import itemMixin from '@/editor/mixins/item';
import { track } from '@/services/xray';
import { getNameFromURL } from '@om/template-properties/src/imageReplace';

export default {
  mixins: [itemMixin],
  data: () => ({
    showReplaceToggle: false,
  }),
  computed: {
    ...mapState([
      'selectedElement',
      'showImageManager',
      'showImageManagerV2',
      'selectedPage',
      'mobilePreview',
      'savedThemeKit',
      'images',
      'imageManagerLogoUpload',
      'colorPicker',
    ]),
    ...mapGetters(['imageReplaceValue', 'hasEditorBatchImageChange']),
    isImageManagerShown() {
      return this.showImageManager || this.showImageManagerV2;
    },
    imageReplace: {
      get() {
        return this.imageManagerLogoUpload || this.imageReplaceValue;
      },
      set(value) {
        this.saveImageReplaceValue(value);
        this.setImageReplaceValue(value);
      },
    },
    currentImage() {
      const element = this.colorPicker?.colorInstance?.element ?? this.selectedElement;

      const selectedImage = get(element, `${this.editedDeviceType}.background`, {});
      if (selectedImage.imageUrl && selectedImage.imageId) {
        return {
          imageUrl: `${selectedImage.imageUrl}`,
          imageId: `${selectedImage.imageId}`,
        };
      }

      const selectedLogoImage = this.images.find(
        (image) => image.name === this.themeKit?.logo?.current,
      );
      return selectedLogoImage?.url && selectedLogoImage?._id
        ? {
            imageUrl: `${selectedLogoImage.url}`,
            imageId: `${selectedLogoImage._id}`,
          }
        : null;
    },
    isModalNeeded() {
      return this.hasEditorBatchImageChange && this.imageReplaceValue && this.showReplaceToggle;
    },
  },
  watch: {
    isImageManagerShown: {
      async handler(value) {
        if (!value) return;
        const currentImage = { ...this.currentImage };
        const result = await this.isImageUsedElsewhere({ currentImage });
        this.showReplaceToggle = result;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['saveImageReplaceValue', 'isImageUsedElsewhere']),
    ...mapMutations(['setImageReplaceValue']),
    setValue(value) {
      track('editor_click', {
        location: 'image_manager',
        component: 'replace_toggle',
        change: value ? 'on' : 'off',
        pageName: this.selectedPage?.data?.title ?? 'Unknown',
        device: this.mobilePreview ? 'mobile' : 'desktop',
      });
      this.setImageReplaceValue(value);
    },
    showImageReplaceIfNeeded({ currentImage, selectedImage }) {
      if (this.isModalNeeded) {
        this.$modal.show('image-replace', { currentImage, selectedImage });
      }
    },
    getNameFromURL(url) {
      return getNameFromURL(url);
    },
  },
};
